import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getCrop, readFile } from "../../../controller/globalController";
import { updateImageProfile } from "../../../controller/userProfileController";
import { changeUser } from "../../../redux/globalAction";
import { globalStorage } from "../../../utils/Storage";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import { Button } from "../../CustomComponent/StyledComponent";
import "react-image-crop/dist/ReactCrop.css";
import style from "../../../css/cropImage.module.css";

const initState = {
  is_loading: false,
  crop: undefined,
  complete_crop: undefined,
  _baseImg: undefined,
};

function UserImageCropModalView({ title = "", toggle, popupHandler, baseImg }) {
  const imageRef = useRef();
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.global.userData);
  const [state, setState] = useState(initState);
  const { is_loading, crop, complete_crop, _baseImg } = state;
  const aspect = 1 / 1;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const centerAspectCrop = (widthImg, heightImg) => {
    return centerCrop(
      makeAspectCrop({ unit: "%", width: 40 }, aspect, widthImg, heightImg),
      widthImg,
      heightImg
    );
  };
  const imageOnLoad = async (e) => {
    const { width, height } = e.currentTarget;
    changeState({ crop: centerAspectCrop(width, height) });
  };

  const handleSubmit = async () => {
    changeState({ is_loading: true });
    let image = await getCrop(imageRef.current, complete_crop, true, 500, 500);
    let res = await updateImageProfile(image.img);
    changeState({ is_loading: false });
    if (res) {
      userData.logo = res;
      globalStorage.setItem("UD", userData);
      dispatch(changeUser(userData));
      Swal.fire({ title: "Berhasil mengubah", icon: "success" }).then((err) =>
        popupHandler(res)
      );
    } else Swal.fire({ title: "Gagal mengubah", icon: "error" });
  };

  const intitImg = async () => {
    const img = await readFile(baseImg);
    changeState({ _baseImg: img });
  };

  useEffect(() => {
    intitImg();
    return () => setState(initState);
  }, []);

  return (
    <CustomModal outsideClick toggle={toggle} modalHandler={popupHandler}>
      <HeaderModal title={title} modalHandler={popupHandler} />
      <div className={style.cropper_container}>
        <ReactCrop
          locked
          crop={crop}
          onChange={(c) => changeState({ crop: c })}
          onComplete={(c) => changeState({ complete_crop: c })}
          circularCrop={true}
        >
          <img
            ref={imageRef}
            src={_baseImg}
            onLoad={imageOnLoad}
            className={style.base_img}
          />
        </ReactCrop>
      </div>

      <Button active onClick={() => handleSubmit()} disabled={is_loading}>
        {!is_loading ? "Ok" : "Loading..."}
      </Button>
    </CustomModal>
  );
}

export default UserImageCropModalView;
