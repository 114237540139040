import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import "react-image-crop/dist/ReactCrop.css";
import style from "../../../css/cropImage.module.css";
import { Button } from "../../CustomComponent/StyledComponent";
import { getCrop, readFile } from "../../../controller/globalController";

const initState = {
  is_loading: false,
  crop: undefined,
  complete_crop: undefined,
  _baseImg: undefined,
};
function SelllerImageCropModalView({
  toggle,
  popupHandler,
  baseImg,
  title = "",
  round = false,
  _aspect,
  size = 100,
  ...props
}) {
  const imageRef = useRef();
  const aspect = _aspect ?? (round ? 1 / 1 : 4 / 1);
  const [state, setState] = useState(initState);
  const { is_loading, crop, complete_crop, _baseImg } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const centerAspectCrop = (widthImg, heightImg) => {
    return centerCrop(
      makeAspectCrop({ unit: "%", width: size }, aspect, widthImg, heightImg),
      widthImg,
      heightImg
    );
  };
  const imageOnLoad = async (e) => {
    const { width, height } = e.currentTarget;
    changeState({ crop: centerAspectCrop(width, height) });
  };

  const handleSubmit = async () => changeState({ is_loading: true });
  const cropImg = async () => {
    let w = props?.width ?? (aspect === 1 ? 500 : 1200);
    let h = props?.width ?? (aspect === 1 ? 500 : 400);
    let image = await getCrop(imageRef.current, complete_crop, round, w, h);
    popupHandler(image);
  };

  const intitImg = async () => {
    changeState({ is_loading: true });
    const img = await readFile(baseImg);
    changeState({ _baseImg: img, is_loading: false });
  };

  // UseEffect
  useEffect(() => {
    intitImg();
    return () => setState(initState);
  }, []);

  useEffect(() => {
    if (complete_crop && is_loading) cropImg();
  }, [is_loading]);

  return (
    <CustomModal outsideClick toggle={toggle} modalHandler={popupHandler}>
      <HeaderModal title={title} modalHandler={popupHandler} />
      <div className={style.cropper_container}>
        <ReactCrop
          locked
          crop={crop}
          onChange={(c) => changeState({ crop: c })}
          onComplete={(c) => changeState({ complete_crop: c })}
          circularCrop={round}
        >
          <img
            ref={imageRef}
            src={_baseImg}
            onLoad={imageOnLoad}
            className={style.base_img}
          />
        </ReactCrop>
      </div>
      <Button active onClick={() => handleSubmit()} disabled={is_loading}>
        {is_loading ? "Loading..." : "Ok"}
      </Button>
    </CustomModal>
  );
}

export default SelllerImageCropModalView;
