import React from "react";
import { useLocation } from "react-router-dom";
import style from "../../css/mainCSS/footer.module.css";
import Footer1Toko from "./Footer1Toko";
import { useSelector } from "react-redux";
import FooterMultipro from "./FooterMultipro";

export const FooterPart = (props) => {
  const { title = "", children } = props;
  return (
    <div className={style.part_container}>
      <div className={style.part_title}>{title}</div>
      {children}
    </div>
  );
};

function Footer(props) {
  const location = useLocation();
  const storeData = useSelector((s) => s.global.storeData);
  const path = location.pathname.split("/")[1];
  const exception_page = [
    "login", "cart", "print", "register", "midtrans", "user_profile",
    "seller_dashboard", "create_store", "reset_password", "ecosystem_login"
  ];
  const exception_flag = exception_page.includes(path);
  const multiproFlag = [308, 27, 46].includes(storeData?.id ?? undefined)

  // view
  if (exception_flag) return <></>;
  if (multiproFlag) return <FooterMultipro />
  return <Footer1Toko />
}

export default Footer;
