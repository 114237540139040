import React from 'react';
import style from "../../css/mainCSS/footer.module.css";
import { FooterPart } from './Footer';

function FooterMultipro(props) {
    return (
        <div className={`body_part_container ${style.container}`}>
            <FooterPart title="Bantuan">
                <a href="">{`Cara Pemesanan`}</a>
                <a href="">{`Kebijakan Privasi`}</a>
            </FooterPart>
            <FooterPart title="Hubungi Kami">
                <a href="">
                    <i className="bi bi-envelope" />
                    {`beli@multipro.co.id`}
                </a>
                <a href="">
                    <i className="bi bi-telephone-fill" />
                    {`(CS) +62 811 8286 128`}
                </a>
                <a href="">
                    <i className="bi bi-telephone-fill" />
                    {`(021) 2942 9151`}
                </a>
            </FooterPart>
            <FooterPart title="Ikuti Kami">
                <a href={`https://www.youtube.com/@multiproid?app=desktop`} target="blank">
                    <i className="bi bi-youtube" />
                    {`multipro.id`}
                </a>
                <a href={`https://www.instagram.com/multipro.id`} target="blank">
                    <i className="bi bi-instagram" />
                    {`@multipro.id`}
                </a>
            </FooterPart>
        </div>
    );
}

export default FooterMultipro;