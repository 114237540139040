import Routers from "./routers/Routers"
import Header from "./components/Header/Header"
import Footer from "./components/Footer/Footer.jsx"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BASE_URL_IMG, DOMAIN, MERCHANT_1TOKO_LINK, webLink } from "./utils/Constants"
import { changeStore, changeUser } from "./redux/globalAction"
import LoginModalView from "./components/Login/LoginModalView"
import { getStoreInfo, getUserProfile, isDomain } from "./controller/authController"
import { globalStorage } from "./utils/Storage"
import Cookies from "js-cookie"
import { useLocation } from "react-router-dom"
// import HeaderUpdateAppView from "./components/Header/HeaderUpdateAppView";
import Loading from "./components/Loading/Loading"

// css
import "./css/mainCSS/app.css"
import "react-datepicker/dist/react-datepicker.css"
import { generateSearchTag } from "./controller/globalController"
import "react-loading-skeleton/dist/skeleton.css"

function App() {
	const storeData = useSelector((s) => s.global.storeData)
	const location = useLocation()
	const dispatch = useDispatch()
	const s_flag = MERCHANT_1TOKO_LINK !== webLink

	// function
	const setStore = (data) => {
		dispatch(changeStore(data))
		var root = document.querySelector(":root")
		root.style.setProperty("--main_color", data?.color_code ?? "#583985") //set main color
		document.title = data?.name ?? "1Toko"
	}

	const setUser = async () => {
		const token = Cookies.get("access_token")
		let storageData = globalStorage.getItem("UD")
		// console.log(storageData);
		if (token) {
			if (!storageData || storageData === "undefined") {
				storageData = await getUserProfile()
				if (storageData !== null) {
					globalStorage.setItem("UD", storageData)
					dispatch(changeUser(storageData))
				}
			} else {
				dispatch(changeUser(storageData))
			}
		}
	}

	// use effect
	const initPage = async () => {
		let search = globalStorage.getItem("STag") // get search tag
		let dwn_app = globalStorage.getItem("DownApp") // get download tag
		if (!search) globalStorage.setItem("STag", generateSearchTag())
		if (dwn_app === undefined) globalStorage.setItem("DownApp", true)

		if (s_flag) {
			const store_link = isDomain ? webLink.replace("www.", "") : webLink.replace("www.", "").replace(`.${DOMAIN}`, "")
			// let data = globalStorage.getItem("SD"); // get session
			// if (!data || store_link !== data?.subdomain) {
			//   data = await getStoreInfo(store_link); // get store data
			// }
			const data = await getStoreInfo(store_link)
			var link = document.querySelector("link[rel~='icon']")
			if (!link) {
				link = document.createElement("link")
				link.rel = "icon"
				document.head.appendChild(link)
			}
			link.href = `${BASE_URL_IMG}100/100/${data.id}/${data.logo}`
			globalStorage.setItem("SD", data) // set session
			setStore(data)
		} else {
			globalStorage.removeItem("SD")
			setStore(undefined)
		}

		await setUser()
	}

	useEffect(() => initPage(), [location])
	if (!storeData && s_flag) {
		return (
			<div style={{ height: "100vh" }}>
				<Loading />
			</div>
		)
	}

	// view
	return (
		<div style={{ minHeight: "100vh" }}>
			{/* <HeaderUpdateAppView store_flag={!s_flag} /> */}
			<Header />
			<Routers />
			<Footer />
			{/* modal */}
			<LoginModalView />
		</div>
	)
}

export default App
