import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../controller/globalController";
import { updateProductActive, updateProductPriceAndStok } from "../../../controller/sellerProductController";
import style from "../../../css/sellerProfile/createProduct/sellerProduct.module.css";
import _style from "../../../css/sellerProfile/createProduct/sellerProductVarian.module.css";
import { BASE_URL_IMG } from "../../../utils/Constants";
import useDebounce from "../../../utils/useDebounce";
import { CCurrencyInput } from "../../CustomComponent/StyledComponent";
import Switcher from "../../CustomComponent/Switcher";
import ActionMenuView from "./ActionMenuView";
import SellerProductDetailCardView from "./SellerProductDetailCardView";

function SellerProductCardView({ data, lastElRef = null, ...props }) {
  // init data
  const cg_flag = useSelector((s) => s.global.cgFlag);
  const product_id = data.id;
  // const etalase = data.etalase_name !== "" ? ` - ${data.etalase_name}` : "";
  const etalase = "";
  const sellerId = useSelector((s) => s.global.userData.seller.id);

  //product var
  const base_img = `${BASE_URL_IMG}70/70/${sellerId}/${data.photo[0]?.name}`;
  const varian_flag = data.varian.length !== 0;
  const range_price = data.range_price.trim("").replaceAll(",", ".");

  const range_discount_price = (data.range_discount_price ?? "").trim("").replaceAll(",", ".");
  const price = parseInt(data?.price ?? 0);
  const stock = varian_flag ? data?.total_stock : parseInt(data?.stock ?? 0);
  const is_active = parseInt(data?.is_active ?? 0) === 1;
  const sku = ["", null, undefined].includes(data?.sku) ? "-" : data.sku;

  // state
  const [state, setState] = useState({ price, range_price, stock, is_active, varian: data.varian });
  const [toggle, setToggle] = useState(false);
  const [toggle1, setToggle1] = useState(false);

  const discount = data.discount;
  const discount_price = varian_flag ? range_discount_price
    : formatCurrency(parseInt(state?.price) - (parseInt(state?.price ?? 0) * discount) / 100);

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNumber = (value, name, values) => changeState({ [name]: value ?? 0 });
  const onBlurNumb = (event) => {
    const { name, value } = event.target;
    if (value === "") changeState({ [name]: data[name] });
    else {
      if (name === "stock") updateProduct(state.price, value);
      if (name === "price") updateProduct(value, state.stock);
    }
  };
  const handleActive = (event) => changeState({ is_active: event.target.checked });
  const handleChangeVarian = (data) => {
    const price = data.range_price.trim("").replaceAll(",", ".");
    const stock = data.total_stock;
    changeState({ price, stock });
  };

  const handleChangeVarianPrimary = (varian_data) => {
    const varian = state.varian.map((data) => {
      if (data.id === varian_data.id) {
        const { is_primary, is_active } = varian_data
        return { ...data, is_primary, is_active };
      }
      return { ...data, is_primary: 0 };
    });
    changeState({ varian });
  };

  const updateStatus = async () => {
    const status = state.is_active ? 1 : 0;
    await updateProductActive(data.id, status);
  };

  const updateProduct = async (price, stock) => {
    const res = await updateProductPriceAndStok(product_id, price, stock);
    if (res) { data.price = price; data.stock = stock; changeState({ price, stock }); }
  };

  // useEffect
  useDebounce(() => updateStatus(), 500, [state.is_active]);

  return (
    <li ref={lastElRef} className={style.card_container}>
      <div className={style.detail_container}>
        <img src={base_img} />
        <div className={style.inner_dtl_container}>
          <div className={style.name_container}>
            <div id={style.name}>{`${data.name}${etalase}`}</div>
            <div id={style.name}>{`SKU : ${sku}`}</div>
          </div>
          <div className={style.inner_part_container}>
            <div className={style.price_container}>
              <div className={style.input_container}>
                <label>Harga</label>
                {varian_flag ? (
                  <div className={style.range_price}>{state?.range_price ?? 0}</div>
                ) : (
                  <div className={style.input_price}>
                    <span>Rp.</span>
                    <CCurrencyInput name="price" value={state.price ?? 0}
                      onValueChange={changeNumber}
                      onBlur={(e) => onBlurNumb(e)}
                      onFocus={e => e.target.select()}
                      disabled={cg_flag}
                    />
                  </div>
                )}
              </div>
            </div>
            {discount !== 0 && (
              <div className={style.price_container}>
                <div className={style.input_container}>
                  <label>Harga Diskon</label>
                  <div className={style.range_price}>{discount_price}</div>
                </div>
              </div>
            )}

            <div className={style.input_container}>
              <label>Stok</label>
              {varian_flag ? (
                <div className={style.range_price}>{state.stock}</div>
              ) : (
                <CCurrencyInput name="stock" value={state.stock ?? 0}
                  onValueChange={changeNumber}
                  onBlur={(e) => onBlurNumb(e)}
                  onFocus={e => e.target.select()}
                  disabled={cg_flag}
                />
              )}
            </div>
            <div className={style.action_container}>
              {!cg_flag && (
                <>
                  <ActionMenuView toggle={toggle1} changeToggle={setToggle1} product_id={product_id} />
                  <Switcher id={data.id} toggle={state.is_active} onChangeToggle={handleActive} />
                </>
              )}
              {cg_flag && (
                <label className={style.cg_product_status}
                  style={{ backgroundColor: `${state.is_active ? "green" : "red"}` }}
                >
                  {state.is_active ? "Aktif" : "Non-Aktif"}
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* list varian */}
      {varian_flag && (
        <div className={_style.container}>
          <div className={_style.btn_container} onClick={() => setToggle(!toggle)}>
            <label>Lihat varian produk</label>
            <i className={`bi bi-chevron-${toggle ? "up" : "down"}`} />
          </div>
          <div className={`${_style.list_container} ${_style[toggle ? "down" : "up"]}`}>
            {state.varian.length !== 0 && state.varian.map((data, idx) => {
              return (
                <SellerProductDetailCardView
                  data={data}
                  product_id={product_id}
                  key={`${data.id}_${idx}`}
                  handleChangeVarian={handleChangeVarian}
                  handleChangeVarianPrimary={handleChangeVarianPrimary}
                />
              );
            })}
          </div>
        </div>
      )}
    </li>
  );
}

export default SellerProductCardView;
